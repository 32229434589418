var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",attrs:{"color":"white","justify":"center","align":"center","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pa-2 rounded-lg center",attrs:{"color":"white","elevation":_vm.$vuetify.breakpoint.mobile ? 8 : 0,"width":"100%"}},[_c('v-card-title',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-login-title"},[_vm._v(" "+_vm._s(_vm.$t("AuthenticationPassword.by_email.title"))+" ")])]),_c('v-card-subtitle',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-login-auth-sub"},[_vm._v(" "+_vm._s(_vm.$t("AuthenticationPassword.by_email.Labels.sub_title"))+" ")])]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('CardTextField',{attrs:{"rules":_vm.rulesField.code,"hint":_vm.$t(
								'AuthenticationPassword.by_email.Labels.code_verification'
							),"reference":"code","placeholder":_vm.$t(
								'AuthenticationPassword.by_email.Labels.code_verification'
							),"label":_vm.$t(
								'AuthenticationPassword.by_email.Labels.code_verification'
							),"icon_add":false,"error":false,"counter":false,"required":false,"hide_details":true,"persistent_hint":true,"persistent_placeholder":false,"disabled":_vm.isLoading},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('CardSwitch',{attrs:{"reference":"save_device","label":_vm.$t('save_device.active'),"color":"success"},model:{value:(_vm.save_device),callback:function ($$v) {_vm.save_device=$$v},expression:"save_device"}})],1)],1)],1),_c('v-card-text',[_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"btn-white",attrs:{"block":"","color":"#73bfc2","loading":_vm.localLoading,"type":"submit","disabled":!_vm.code}},[_vm._v(" "+_vm._s(_vm.$t("AuthenticationPassword.by_email.actions.submit"))+" ")])],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[(_vm.isFactorEmail)?_c('v-btn',{staticClass:"btn-turquoise",attrs:{"plain":""},on:{"click":_vm.handleResend}},[_vm._v(" "+_vm._s(_vm.$t("AuthenticationPassword.by_email.actions.resend"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"btn-turquoise",attrs:{"plain":"","to":"/auth/login"}},[_vm._v(" "+_vm._s(_vm.$t("AuthenticationPassword.by_email.actions.cancel"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }