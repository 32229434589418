import { isRequired } from "@/services/rule-services";
import { mapActions, mapGetters } from "vuex";
import { TwoFactorCode } from '@/interfaces/user';
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";

const FACTOR_EMAIL = "email";

export default {
	name: "Forgot",
	props: {},
	data: () => ({
		localLoading: false,
		code: "",
		save_device: false,
		valid: false,
		isEmailed: false,
		rules: { isRequired },
		rulesField:{
            code: [],
        }
	}),
	created() {
		this.$nextTick(async () => {
			document.title = this.$t("forgotPassword.title");
		});
	},
	mounted() {},
	computed: {
		...mapGetters("account", ["getTwoFactorData"]),
		...mapGetters("proccess", ["isLoading"]),

		getType() {
			return this.getTwoFactorData.typeFactor;
		},

		getEmail() {
			return this.getTwoFactorData.emailFactor;
		},

		getPassword() {
			return this.getTwoFactorData.password;
		},

		isFactorEmail(){
			return this.getType == FACTOR_EMAIL;
		}
	},
	components: { CardTextField, CardSwitch },
	methods: {

		...mapActions("auth", ["TwoFactorAuthenticator", "logIn"]),

		async setLoading(load: Boolean = false) {
			this.localLoading = load;
		},

		async validate() {
			let form = this.$refs.form;
			return form.validate();
		},

		async addRules(){
            this.rulesField.code = [this.rules.isRequired];
        },

        async clearRules(){
            this.rulesField.code = [];
        }, 

		async handleSubmit() {
			try {
				await this.addRules();
				if (!(await this.validate())) return;
				this.setLoading(true);
				
				const data = {
                    email: this.getEmail,
                    two_factor_code: this.getType == "google" ? String(this.code) : Number(this.code),
					save_device: this.save_device,
                } as TwoFactorCode;

				const response = await this.TwoFactorAuthenticator(data);

				this.setLoading(false);
				await this.clearRules();

				if (response.success) {
					this.$router.push({ name: "Root" });
				}

			} catch (error) {
				this.setLoading(false);
				await this.clearRules();
			}
		},

		async handleResend(){
			try {
				await this.logIn({
					email: this.getEmail,
					password: this.getPassword,
				});
			} catch (error) {
				
			}
		},

		backToLogin() {
			this.$router.push("/auth/login");
		},
	},

	watch: {
		code(val: string) {
			if(val){
				this.rulesField.code = [this.rules.isRequired];
			}else{
				this.rulesField.code = [];
			}
		},
	},
};
